$('.login').on('click',function(){
  $('#g-recaptcha-response')[0].value = $('.g-recaptcha-response')[0].value
});

$(document).off('click', '.input-password__show').on('click', '.input-password__show', function (e) {
  e.preventDefault()
  const input = $(this).next().find('input')
  if (input.attr('type') === 'password') {
    input.attr('type', 'text')
    $(this).addClass('ri-eye-off-line')
    $(this).removeClass('ri-eye-line')
  } else {
    input.attr('type', 'password')
    $(this).removeClass('ri-eye-off-line')
    $(this).addClass('ri-eye-line')
  }
})
